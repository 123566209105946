import {
  Box,
  Container,
  Divider,
  IconButton,
  Link,
  AppBar as MUIAppBar,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useCallback, useContext, useEffect } from "react";
import moment from "moment";
import config from "../config";
import Hidden from "./HiddenSSRCompatible";
import { useRouter } from "next/router";
import MobileMenu from "./MobileMenu";
import OrganizationSwitcher from "./organization/OrganizationSwitcher";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { HelpOutline as HelpOutlineIcon } from "@material-ui/icons";
import { StoreContext } from "../store";
import useTranslation from "next-translate/useTranslation";
import { buildStepsGuide } from "./shepherdStep/guideStep";
import OrganizationNotifications from "./organization/OrganizationNotifications";
import GetScreenType from "./screenType";

function EnvironmentBar() {
  const { t } = useTranslation("common");
  return config.DEMO_MODE || config.NODE_ENV === "development" ? (
    <Box
      color="primary.contrastText"
      bgcolor={config.DEMO_MODE ? "success.dark" : "grey.700"}
      fontSize="caption.fontSize"
      textAlign="center"
      py={0.2}
    >
      {config.DEMO_MODE ? t("Demonstration mode") : t("Development mode")}
    </Box>
  ) : null;
}

// function TrialBar() {
//   const { t } = useTranslation('common');
//   const store = useContext(StoreContext);

//   // TODO: Trial Bar based on landlord account
//   return store.user.plan == "trial" && store.user.trialEndDate && (
//     <Box boxShadow={3} bgcolor="#FFD140">
//       <Container>
//         <Box paddingX={.5} paddingY={.25} display="flex" justifyContent="center">
//           <Typography>
//             {t('Your account is still in trial period, active until {{trialEndDate}}.', {
//               trialEndDate: moment(store.user.trialEndDate).format("ll"),
//             })}
//           </Typography>
//         </Box>
//       </Container>
//     </Box>
//   )
// }

function MainToolbar({ maxWidth, onSignOut, currentTab }) {
  const { t } = useTranslation("common");
  const router = useRouter();
  const store = useContext(StoreContext);
  // fetch notification listing
  const fetchNotificationList = async () => {
    try {
      const response = await store.user.fetchNotifications(store.user._id);
    } catch (error) {
      console.log("error");
    }
  };
  useEffect(() => {
    fetchNotificationList();
  }, [currentTab]);
  if (!store.user?.signedIn) {
    return null;
  }

  return (
    <>
      <Hidden smDown>
        <Box ml={7}>
          <Toolbar disableGutters>
            <Container maxWidth="xl">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Box fontSize="h5.fontSize" className="shepherd-currentPage">
                  {t(`${currentTab}`)}
                </Box>
                <Box display="flex" alignItems="center">
                  <OrganizationSwitcher index={1} />
                  {/* <Tooltip title={t('Page Guide')} aria-label="page-guide">
                    <IconButton
                      aria-label="page-guide"
                      onClick={handleStartTour}
                      color="default"
                      data-cy="pageguide"
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip> */}
                  <OrganizationNotifications />
                  <Tooltip title={t("Sign out")} aria-label="sign out">
                    <IconButton
                      aria-label="sign out"
                      onClick={onSignOut}
                      color="default"
                      data-cy="signout"
                    >
                      <PowerSettingsNewIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Container>
          </Toolbar>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <MobileMenu>
          <Toolbar disableGutters>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <OrganizationSwitcher index={0} />
              <Box style={{ display: "flex" }}>
                {/* <OrganizationNotifications /> */}
                <IconButton
                  aria-label="sign out"
                  onClick={onSignOut}
                  color="inherit"
                  data-cy="signout"
                >
                  <PowerSettingsNewIcon />
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        </MobileMenu>
      </Hidden>
    </>
  );
}

function TrialBar() {
  const { t } = useTranslation("common");
  const store = useContext(StoreContext);

  return (
    store.user.plan == "trial" &&
    store.user.trialEndDate && (
      <>
        <Hidden smDown>
          <Box boxShadow={3} bgcolor="#FFD140">
            <Container>
              <Box
                paddingX={0.5}
                paddingY={0.25}
                display="flex"
                justifyContent="center"
              >
                <Typography>
                  {t(
                    "Your account is still in trial period, active until {{trialEndDate}}.",
                    {
                      trialEndDate: moment(store.user.trialEndDate).format(
                        "ll"
                      ),
                    }
                  )}
                  {t(" Click")}
                  <Link
                    href="https://api.whatsapp.com/send?phone=6281382586868&text=Hi,%20saya%20ingin%20berlangganan%20aplikasi%20Superkos"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="blue"
                    underline="none"
                    style={{ marginLeft: "5px", marginRight: "5px" }}
                  >
                    {t("here")}
                  </Link>
                  {t("to subscribe")}
                </Typography>
              </Box>
            </Container>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box boxShadow={2} bgcolor="#FFD140">
            <Container>
              <Typography>
                {t(
                  "Your account is still in trial period, active until {{trialEndDate}}.",
                  {
                    trialEndDate: moment(store.user.trialEndDate).format("ll"),
                  }
                )}
                {t(" Click")}
                <Link
                  href="https://api.whatsapp.com/send?phone=6281382586868&text=Hi,%20saya%20ingin%20berlangganan%20aplikasi%20Superkos"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="blue"
                  underline="none"
                  style={{ marginLeft: "5px", marginRight: "5px" }}
                >
                  {t("here")}
                </Link>
                {t("to subscribe")}
              </Typography>
            </Container>
          </Box>
        </Hidden>
      </>
    )
  );
}

export default function AppBar() {
  const store = useContext(StoreContext);
  const router = useRouter();
  const { screenType } = GetScreenType();
  if (router.pathname === "/404") {
    return null;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleSignOut = useCallback(
    async (event) => {
      event.preventDefault();
      await store.user.removeDeviceToken(store.user.email);
      await store.user.signOut();
      
    },
    [store.user]
  );

  const pathParts = router.pathname.split("/");
  const organizationIndex = pathParts.indexOf("[organization]");
  const currentPath = pathParts[1];
  const capitalizedCurrentPath =
    currentPath.charAt(0).toUpperCase() + currentPath.slice(1);

  // if (router.pathname === "/dashboard") {
  //   return (
  //     <>
  //       <Hidden mdUp>
  //         <EnvironmentBar />
  //         <TrialBar />
  //         <MainToolbar onSignOut={handleSignOut} />
  //       </Hidden>
  //       <Hidden smDown>
  //         <EnvironmentBar />
  //         <TrialBar />
  //       </Hidden>
  //     </>
  //   );
  // }
  return (
    <>
      <Hidden smDown>
        <EnvironmentBar />
        <TrialBar />
        {router.pathname === "/dashboard" ? null : (
          <MainToolbar
            currentTab={capitalizedCurrentPath}
            onSignOut={handleSignOut}
          />
        )}
      </Hidden>
      <Hidden mdUp>
        {router.pathname == "/chat" && screenType === "Mobile" ? (
          ""
        ) : (
          <>
            <EnvironmentBar />
            <TrialBar />
            <MainToolbar onSignOut={handleSignOut} />
          </>
        )}
      </Hidden>
    </>
  );
}
