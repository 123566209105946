import { action, computed, flow, makeObservable, observable } from 'mobx';
import api from '../utils/apiService';
import { apiFetcher } from '../utils/fetch';
import { updateItems } from './utils';
import { makePersistable } from 'mobx-persist-store';
import { FlipTwoTone } from '@material-ui/icons';


export default class Property {
  constructor() {
    this.selected = {};
    this.filters = { searchText: '' };
    this.items = [];
    this.filtersbyRole = [];
    this.qrCodeData = {};  // Define observable state for qrCodeData

    makeObservable(this, {
      selected: observable,
      filters: observable,
      items: observable,
      filteredItems: computed,
      filteredItemsByRole: computed,
      setSelected: action,
      setItems: action,
      setFilters: action,
      setFiltersByRole: action,
      setQrCodeData: action,
      filtersbyRole: observable,
      qrCodeData: observable,
      fetch: flow,
      fetchOne: flow,
      fetchFilterRoleOne: flow,
      create: flow,
      update: flow,
      delete: flow,
      deletePropertyLogo: flow,
      qrForWhatsApp: flow,
      qrConnect: flow,
    });
    makePersistable(this, {
      name: 'Property', properties: ['selected', 'items', 'filters'],
      storage: typeof window !== "undefined" ? window.localStorage : undefined,
      expireIn: 604800000, // 7 day in milliseconds to match refresh token expiry time
      removeOnExpiration: true,
    });

  }

  get filteredItems() {
    let filteredItems = this.items;

    if (this.filters.searchText) {
      const regExp = /\s|\.|-/gi;
      const cleanedSearchText = this.filters.searchText
        .toLowerCase()
        .replace(regExp, '');

      filteredItems = filteredItems.filter(
        ({ name }) =>
          name.replace(regExp, '').toLowerCase().indexOf(cleanedSearchText) !=
          -1
      );
    }
    return filteredItems;
  }

  get filteredItemsByRole() {
    let filteredItemsByRole = this.filtersbyRole;

    if (this.filters.searchText) {
      const regExp = /\s|\.|-/gi;
      const cleanedSearchText = this.filters.searchText
        .toLowerCase()
        .replace(regExp, '');

      filteredItemsByRole = filteredItemsByRole.filter(
        ({ name }) =>
          name.replace(regExp, '').toLowerCase().indexOf(cleanedSearchText) !=
          -1
      );
    }
    return filteredItemsByRole;
  }

  setSelected = (property) => (this.selected = property);

  setFilters = ({ searchText = '' }) =>
    (this.filters = { searchText });

  setItems = (properties) => {
    this.items = updateItems(properties, this.items);
  };

  setFiltersByRole = (properties) => {
    this.filtersByRole = properties;
  };

  *fetch() {
    try {
      const response = yield apiFetcher().get('/properties');

      this.items = response.data;
      if (this.selected?._id) {
        this.setSelected(
          this.items.find((item) => item._id === this.selected._id) || {}
        );
      }
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *fetchOne(propertyId) {
    try {
      const response = yield apiFetcher().get(`/properties/${propertyId}`);
      const updatedProperty = response.data;
      this.items = updateItems(updatedProperty, this.items);
      if (this.selected?._id === updatedProperty._id) {
        this.selected = updatedProperty;
      }
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *fetchFilterRoleOne(propertyId) {
    try {
      const response = yield apiFetcher().get(`/properties/${propertyId}`);
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *create(property) {
    try {
      const response = yield apiFetcher().post('/properties', property);
      const createdProperty = response.data;
      this.items = updateItems(createdProperty, this.items);

      return { status: 200, data: createdProperty };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *update(property) {
    try {
      const response = yield apiFetcher().patch(
        `/properties/${property._id}`,
        property
      );
      const updatedProperty = response.data;
      this.items = updateItems(updatedProperty, this.items);
      if (this.selected?._id === updatedProperty._id) {
        this.setSelected(updatedProperty);
      }
      return { status: 200, data: updatedProperty };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *delete(ids) {
    try {
      yield apiFetcher().delete(`/properties/${ids.join(',')}`);
      return { status: 200 };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *deletePropertyLogo(ids, documentCategory) {
    try {
      yield apiFetcher().delete(`/documents/property-logo/${ids.join(',')}?documentCategory=${documentCategory}`);
      return { status: 200 };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  // Action to update qrCodeData
  setQrCodeData(qrData) {
    this.qrCodeData = qrData;
  }

  *qrForWhatsApp({ propertyId, toggle }) {
    console.log('propertyId ====>>>', propertyId)
    try {
      const toggleString = toggle ? "true" : "false";  // This ensures the value is a string ("true" or "false")
      const response = yield api.post(`/properties/${propertyId}/qr`, {
        toggle: toggleString, // Pass toggle as a query parameter
      });

      if (response.status === 200) {
        const qrData = response.data;
        this.setQrCodeData(qrData); // Update qrCodeData with response

        return { status: 200, data: qrData }; // Return QR data
      } else {
        throw new Error('Failed to fetch QR code');
      }
    } catch (error) {
      console.error("Error fetching QR data:", error);
      return { status: error?.response?.status, message: error?.response?.data?.error };
    }
  }

  *qrConnect() {
    try {
      const response = yield api.get(`/whatsapp/qr-scanned?tenant=${tenant._id}&property=${propertyId}`);
      const qrConnectData = response.data;
      console.log("getQRData == ", qrConnectData)
      return { status: 200, data: createdTenant };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }
}