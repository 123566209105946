import { action, flow, makeObservable, observable } from 'mobx';
import api from '../utils/apiService';

export default class Report {
  constructor() {
    this.items = [];

    makeObservable(this, {
      items: observable,
      fetch: flow,
      profitLoss: flow,
      profit: flow,
      deposit: flow,
      invoice: flow,
      occupant: flow,
      ledger: flow,
      balance: flow,
      setItems: action,
    });
  }

  setItems() {
    this.items = [];
  }

  *fetch(startDate, endDate, propertyId, page = 1, limit = 10) {
    try {
      const response = yield apiFetcher().get(`/report/transaction?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&page=${page}&limit=${limit}`);
      this.items = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }
  *profitLoss(startDate, endDate, propertyId, page = 1, limit = 10) {
    try {
      const response = yield apiFetcher().get(`/report/profitLoss?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&page=${page}&limit=${limit}`);
      this.items = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  *profit(startDate, endDate, propertyId, page = 1, limit = 10) {
    try {
      const response = yield apiFetcher().get(`/report/profit?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&page=${page}&limit=${limit}`);
      this.items = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  *deposit(startDate, endDate, propertyId, page = 1, limit = 10) {
    try {
      const response = yield apiFetcher().get(`/report/deposit?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&page=${page}&limit=${limit}`);
      this.items = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  *invoice(startDate, endDate, propertyId, page = 1, limit = 10) {
    try {
      const response = yield apiFetcher().get(`/report/invoice?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&page=${page}&limit=${limit}`);
      this.items = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  *occupant(startDate, endDate, propertyId, page = 1, limit = 10) {
    try {
      const response = yield apiFetcher().get(`/report/occupant?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&page=${page}&limit=${limit}`);
      this.items = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  *ledger(startDate, endDate, propertyId, page = 1, limit = 10) {
    try {
      const response = yield apiFetcher().get(`/report/ledger?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&page=${page}&limit=${limit}`);
      this.items = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  *balance(startDate, endDate, propertyId, page = 1, limit = 10) {
    try {
      const response = yield apiFetcher().get(`/report/balance?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&page=${page}&limit=${limit}`);
      this.items = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }
}