import { action, flow, makeObservable, observable } from 'mobx';
import api from '../utils/apiService';
import { apiFetcher } from '../utils/fetch';
import { updateItems } from './utils';

export default class Invoice {
  constructor() {
    this.selected = {};
    this.filters = { searchText: '' };
    this.items = [];

    makeObservable(this, {
      selected: observable,
      filters: observable,
      items: observable,
      setSelected: action,
      setFilters: action,
      fetch: flow,
      fetchOne: flow,
      fetchTenantInvoice: flow,
      create: flow,
      batchCreate: flow,
      update: flow,
      updateOccupantInvoices: flow,
      voidInvoicesOfOccupant: flow,
      capturePayment: flow,
      deletePayment: flow,
      delete: flow,
      deleteCanceledTenantInvoices: flow,
      deleteInvoicesOfOccupant: flow,
    });
  }

  get filteredItems() {
    let filteredItems = this.items;

    if (this.filters.searchText) {
      const regExp = /\s|\.|-/gi;
      const cleanedSearchText = this.filters.searchText.toLowerCase().replace(regExp, '');
      filteredItems = filteredItems.filter(({ _id, occupantId }) => {
        let found =
          occupantId.replace(regExp, '').toLowerCase().indexOf(cleanedSearchText) !== -1;
        return found;
      });
    }

    return filteredItems;
  }

  setSelected = (invoice) => (this.selected = invoice);

  setFilters = ({ searchText = '' }) => (this.filters = { searchText });

  *fetch() {
    try {
      const response = yield apiFetcher().get('/invoices');
      this.items = response.data;
      if (this.selected._id) {
        this.setSelected(this.items.find((item) => item._id === this.selected._id) || {});
      }
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *fetchOne(invoiceId) {
    try {
      const response = yield apiFetcher().get(`/invoices/${invoiceId}`);
      const updatedInvoice = response.data;
      this.items = updateItems(updatedInvoice, this.items);
      if (this.selected?._id === updatedInvoice._id) {
        this.setSelected(updatedInvoice);
      }
      return { status: 200, data: updatedInvoice };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *fetchTenantInvoice(tenantId) {
    try {
      const response = yield apiFetcher().get(`/invoices/tenant/${tenantId}`);
      const updatedInvoice = response.data

      // Workaround for updateItems() can't handle array
      if (!this.items.length) {
        this.items = updatedInvoice
      } else {
        this.items = updateItems(updatedInvoice, this.items);
      }

      this.setSelected(updatedInvoice);
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *create(invoice) {
    try {
      const response = yield apiFetcher().post('/invoices', invoice);
      const createdInvoice = response.data;
      this.items = updateItems(createdInvoice, this.items);

      return { status: 200, data: createdInvoice };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *batchCreate({ occupantId, quantity }) {
    try {
      const response = yield api.post('/invoices/batch', { occupantId, quantity });
      const createdInvoice = response.data;
      this.items = updateItems(createdInvoice, this.items);

      return { status: 200, data: createdInvoice };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *update(invoice) {
    try {
      const response = yield apiFetcher().patch(`/invoices/${invoice._id}`, invoice);
      const updatedInvoice = response.data;
      this.items = updateItems(updatedInvoice, this.items);
      if (this.selected?._id === updatedInvoice._id) {
        this.setSelected(updatedInvoice);
      }

      return { status: 200, data: updatedInvoice };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *voidInvoicesOfOccupant(tenantId) {
    try {
      yield apiFetcher().patch(`invoices/set-void/tenant/${tenantId}`);
      return { status: 200 };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *updateOccupantInvoices(tenantId, query) {
    try {
      yield apiFetcher().patch(`/invoices/tenant/${tenantId}${query}`);
      return { status: 200 };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *capturePayment(invoice) {
    try {
      const response = yield apiFetcher().patch(`/invoices/${invoice._id}/capture`, invoice.payment)
      const updatedInvoice = response.data;
      this.items = updateItems(updatedInvoice, this.items);
      return { status: 200, data: updatedInvoice };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *deletePayment(invoice) {
    try {
      const response = yield apiFetcher().patch(`/invoices/${invoice._id}/delete/${invoice.transactionId}`)
      const updatedInvoice = response.data
      this.items = updateItems(updatedInvoice, this.items)
      return { status: 200 };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *delete(ids) {
    try {
      yield apiFetcher().delete(`/invoices/${ids.join(',')}`);
      return { status: 200 };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *deleteCanceledTenantInvoices(tenantId) {
    try {
      yield apiFetcher().delete(`/invoices/tenant/${tenantId}`);
      return { status: 200 };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *deleteInvoicesOfOccupant(tenantId, query) {
    try {
      // TODO: yield api
      yield apiFetcher().delete(`/invoices/tenant/${tenantId}?${query}`)
      return { status: 200 };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }
}
