import { action, computed, flow, makeObservable, observable } from 'mobx';

import _, { filter } from 'lodash';
import { apiFetcher } from '../utils/fetch';
import moment from 'moment';
import api from '../utils/apiService';

// workaround for this momentjs behavior:
// As of 2.8.0, changing the global locale doesn't affect existing instances.
// https://momentjscom.readthedocs.io/en/latest/moment/06-i18n/01-changing-locale/
function getMoment(current) {
  return moment(current);
}
export default class Rent {
  constructor() {
    this.selected = {};
    this.filters = { searchText: '', status: [] };
    this._period = moment();
    this.items = [];
    this.countTenantActive = undefined;
    this.countInvoiceBill = undefined;
    this.invoiceBill = undefined;
    this.countTotalPaid = undefined;
    this.totalPaid = undefined;
    this.countTotalOverdue = undefined;
    this.totalOverdue = undefined;
    this.latestCountTenantActive = undefined;
    this.latestCountInvoiceBill = undefined;
    this.latestInvoiceBill = undefined;
    this.latestCountTotalPaid = undefined;
    this.latestTotalPaid = undefined;
    this.latestCountTotalOverdue = undefined;
    this.latestTotalOverdue = undefined;

    makeObservable(this, {
      selected: observable,
      filters: observable,
      items: observable,
      countTenantActive : observable,
      countInvoiceBill : observable,
      invoiceBill : observable,
      countTotalPaid : observable,
      totalPaid : observable,
      countTotalOverdue : observable,
      totalOverdue : observable,
      latestCountTenantActive : observable,
      latestCountInvoiceBill : observable,
      latestInvoiceBill : observable,
      latestCountTotalPaid : observable,
      latestTotalPaid : observable,
      latestCountTotalOverdue : observable,
      latestTotalOverdue : observable,
      _period: observable,
      period: computed,
      periodAsString: computed,
      filteredItems: computed,
      setSelected: action,
      setFilters: action,
      setPeriod: action,
      fetchNoFilterWithoutUpdatingStore: flow,
      fetchNoFilter: flow,
      fetchOneTenantRent: flow,
      fetchTenantRents: flow,
      pay: flow,
      sendEmail: flow,
      sendWhatsapp: flow,
    });
  }

  get period() {
    return getMoment(this._period);
  }

  get periodAsString() {
    return this._period.format('YYYY.MM');
  }

  get filteredItems() {
    let filteredItems =
      this.filters.status?.length === 0
        ? this.items
        : this.items.filter(({ status }) =>
          this.filters.status.includes(status)
        );

    if (this.filters.searchText) {
      const regExp = /\s|\.|-/gi;
      const cleanedSearchText = this.filters.searchText
        .toLowerCase()
        .replace(regExp, '');

      filteredItems = filteredItems.filter(
        ({ occupant: { isCompany, name, manager, contacts, room }, payments }) => {
          // Search match name
          let found =
            name.replace(regExp, '').toLowerCase().indexOf(cleanedSearchText) !=
            -1;

          // Search match manager
          if (!found && isCompany) {
            found =
              manager
                .replace(regExp, '')
                .toLowerCase()
                .indexOf(cleanedSearchText) != -1;
          }

          // Search match contact
          if (!found) {
            found = !!contacts
              ?.map(({ contact = '', email = '', phone = '' }) => ({
                contact: contact.replace(regExp, '').toLowerCase(),
                email: email.toLowerCase(),
                phone: phone.replace(regExp, ''),
              }))
              .filter(
                ({ contact, email, phone }) =>
                  contact.indexOf(cleanedSearchText) != -1 ||
                  email.indexOf(cleanedSearchText) != -1 ||
                  phone.indexOf(cleanedSearchText) != -1
              ).length;
          }

          // Search match in payment references
          if (!found) {
            found = !!payments?.find(
              ({ reference = '' }) =>
                reference
                  .replace(regExp, '')
                  .toLowerCase()
                  .indexOf(cleanedSearchText) != -1
            );
          }

          // Search match room
          if (!found && room) {
            found =
              room.name
                .replace(regExp, '')
                .toLowerCase()
                .indexOf(cleanedSearchText) != -1;
          }
          
          return found;
        }
      );
    }
    return filteredItems;
  }
  setSelected = (rent) => (this.selected = rent);

  setFilters = ({ searchText = '', status = [] }) =>
    (this.filters = { searchText, status });

  setPeriod = (period) => (this._period = getMoment(period));

  *fetchNoFilterWithoutUpdatingStore() {
    try {
      const response = yield api.get(`/rents/all?search=${this.filters.searchText}&status=${this.filters.status.join(',')}`);
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *fetchNoFilter() {
    try {
      const response = yield this.fetchNoFilterWithoutUpdatingStore();

      if (
        !_.isEqual(
          [
            this.countTenantActive,
            this.countInvoiceBill,
            this.invoiceBill,
            this.countTotalPaid,
            this.totalPaid,
            this.countTotalOverdue,
            this.totalOverdue,
            this.latestCountTenantActive,
            this.latestCountInvoiceBill,
            this.latestInvoiceBill,
            this.latestCountTotalPaid,
            this.latestTotalPaid,
            this.latestCountTotalOverdue,
            this.latestTotalOverdue,
          ],
          [
            response.data.overview.countTenantActive,
            response.data.overview.countInvoiceBill,
            response.data.overview.invoiceBill,
            response.data.overview.countTotalPaid,
            response.data.overview.totalPaid,
            response.data.overview.countTotalOverdue,
            response.data.overview.totalOverdue,
            response.data.overviewLast30Days.countTenantActive,
            response.data.overviewLast30Days.countInvoiceBill,
            response.data.overviewLast30Days.invoiceBill,
            response.data.overviewLast30Days.countTotalPaid,
            response.data.overviewLast30Days.totalPaid,
            response.data.overviewLast30Days.countTotalOverdue,
            response.data.overviewLast30Days.totalOverdue,
          ]
        )
      ) {
        this.countTenantActive = response.data.overview.countTenantActive;
        this.countInvoiceBill = response.data.overview.countInvoiceBill;
        this.invoiceBill = response.data.overview.invoiceBill;
        this.countTotalPaid = response.data.overview.countTotalPaid;
        this.totalPaid = response.data.overview.totalPaid;
        this.countTotalOverdue = response.data.overview.countTotalOverdue;
        this.totalOverdue = response.data.overview.totalOverdue;
        this.latestCountTenantActive = response.data.overviewLast30Days.countTenantActive;
        this.latestCountInvoiceBill = response.data.overviewLast30Days.countInvoiceBill;
        this.latestInvoiceBill = response.data.overviewLast30Days.invoiceBill;
        this.latestCountTotalPaid = response.data.overviewLast30Days.countTotalPaid;
        this.latestTotalPaid = response.data.overviewLast30Days.totalPaid;
        this.latestCountTotalOverdue = response.data.overviewLast30Days.countTotalOverdue;
        this.latestTotalOverdue = response.data.overviewLast30Days.totalOverdue;
      }

      if (!_.isEqual(this.items, response.data.rents)) {
        this.items = response.data.rents;
        if (this.selected?._id) {
          this.setSelected(
            this.items.find((item) => item._id === this.selected._id) || {}
          );
        }
      }
      return response;
    } catch (error) {
      return error;
    }
  }

  *fetchOneTenantRent(tenantId, term) {
    try {
      const response = yield apiFetcher().get(
        `/rents/tenant/${tenantId}/${term}`
      );
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *fetchTenantRents(tenantId) {
    try {
      const response = yield apiFetcher().get(`/rents/tenant/${tenantId}`);
      return { status: 200, data: response.data };
    } catch (error) {
      console.error(error);
      return { status: error?.response?.status };
    }
  }

  *pay(term, payment) {
    try {
      const response = yield apiFetcher().patch(
        `/rents/payment/${payment._id}/${term}`,
        payment
      );
      const rent = response.data;
      const index = this.items.findIndex((item) => item._id === payment._id);
      if (index > -1) {
        this.items.splice(index, 1, rent);
      }
      if (this.selected?._id === payment._id) {
        this.setSelected(rent);
      }
      return 200;
    } catch (error) {
      return error.response.status;
    }
  }

  // payload
  // {
  //   document,
  //   tenantIds,
  //   year,
  //   month
  // }
  *sendEmail(payload) {
    try {
      yield apiFetcher().post('/emails', payload);
      return 200;
    } catch (error) {
      return error.response.status;
    }
  }
  *sendWhatsapp(payload) {
    try {
      yield apiFetcher().post('/messages/send', payload);
      return 200;
    } catch (error) {
      return error;
    }
  }
}
